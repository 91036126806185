<template>
  <div class=""></div>
</template>

<script>
export default {
  created() {
    if (process.env.NODE_ENV === "production") {
      document.location.href = "/v1/";
    }
  },
};
</script>
